<template>
  <div>
    <div class="bottom">
      <div class="bottom-box">
        <div class="box-item">
          <div class="title">产品与服务</div>
          <div
            class="text"
            style="margin-top: 10px"
            @click="goRoute('/qcygUs')"
          >
            企才易管
          </div>
          <div class="text" @click="goRoute('/Yunlan')">薪云工资条</div>
          <div class="text" @click="goRoute('/faxinIndex')">禾悦发薪</div>
          <div class="text" @click="goRoute('/lzy')">灵云智</div>
        </div>
        <div class="box-item box-posi">
          <div class="title">联系我们</div>
          <div class="text" style="margin-top: 10px">商务合作/技术支持</div>
          <div class="text">szaihehe.com</div>
          <div class="text">0512-52662888</div>
        </div>

        <div class="box-item column-center">
          <div class="code">
            <img src="@/assets/account.jpg" alt="" />
            <div class="code-text">客服二维码</div>
          </div>
          <div class="code" style="margin-left: 74px">
            <img src="@/assets/code.jpg" alt="" />
            <div class="code-text">公众号二维码</div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="bottom-text column-center">
      网站首页| 联系我们| 法律声明 Copyright © 2020-2025 All Rights Reserved.
      鲁ICP备000000000000号 鲁公网安备00000000000000号
    </div> -->
  </div>
</template>

<script>
export default {
  name: "bottomIndex",
  methods: {
    goRoute(url) {
      this.$router.push(url);
    },
  },
};
</script>

<style scoped>
.bottom-text {
  width: 100%;
  height: 57px;
  background-color: #000926;
  color: #b5b5b5;
  justify-content: center;
}
.box-posi {
  position: relative;
  top: -22px;
}
.column-center {
  display: flex;
  align-items: center;
}
img {
  width: 80px;
  height: 80px;
}
.code {
  background: #ffffff;
  box-sizing: border-box;
  width: 124px;
  height: 134px;
  text-align: center;
  padding-top: 13px;
}
.code-text {
  font-size: 16px;
  font-family: Microsoft YaHei, Microsoft YaHei-Regular;
  font-weight: 400;
  color: #000926;
  margin-top: 5px;
  text-align: center;
}
.text {
  font-size: 18px;
  font-family: Microsoft YaHei, Microsoft YaHei-Regular;
  font-weight: 400;
  color: #b5b5b5;
  line-height: 40px;
  cursor: pointer;
}
.title {
  font-size: 18px;
  font-weight: Bold;
  color: #fff;
}
.box-item {
}
.bottom-box {
  width: 1200px;
  margin: 0 auto;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.bottom {
  width: 100%;
  height: 330px;
  background: #000926;
  border-bottom: 1px solid #484848;
}
</style>
