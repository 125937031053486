const setclass = (entries) => {
  if (entries[0].isIntersecting) {
    entries[0].target.style.opacity = "1";

    if (entries[0].intersectionRect.top < 50) {
      return;
    }
    for (let index = 0; index < entries[0].target.children.length; index++) {
      // observer.classList && observer.classList.forEach(item => {
      //     entries[0].target.children.item(index).classList.add(item)
      // })

      let display = window.getComputedStyle(
        entries[0].target.children.item(index)
      ).display;
      entries[0].target.children.item(index).style.display = "none";
      setTimeout(() => {
        entries[0].target.children.item(index).style.display =
          display == "" ? "block" : display;
      }, 20);

      // entries[0].target.children.item(index).classList.add('animate__animated')
    }
    // console.log(entries[0]);
  } else {
    entries[0].target.style.opacity = "0";
    // for (let index = 0; index < entries[0].target.children.length; index++) {
    // const values = entries[0].target.children.item(index).classList.values()
    // let arr = []

    // for (const iterator of values) {
    //     // console.log(iterator);
    //     if (iterator.indexOf('animate__') !== -1) {
    //         arr.push(iterator)
    //     }
    // }
    // if (!observer.classList && arr.length > 0) {
    //     observer.classList = arr
    // }
    // arr.forEach(item => {
    //     entries[0].target.children.item(index).classList.remove(item)
    // })
    // console.log(entries[0]);
    // console.log(entries[0].target.children.item(index).style.display = 'none');
    // entries[0].target.children.item(index).style.display = 'block'
    // entries[0].target.children.item(index).classList.remove('animate__animated')
    // }
    // console.log(observer.classList);
  }
};

// const Asetclass = (entries, observer) => {
//   if (entries[0].isIntersecting) {
//     entries[0].target.style.opacity = "1";
//     if (entries[0].intersectionRect.top < 50) {
//       return;
//     }
//     for (let index = 0; index < entries[0].target.children.length; index++) {
//       observer.classList.forEach((item) => {
//         if (
//           entries[0].target.children
//             .item(index)
//             .classList.value.indexOf("is-animate") > -1
//         )
//           entries[0].target.children.item(index).classList.add(item);
//       });
//     }
//   } else {
//     entries[0].target.style.opacity = "0";
//     for (let index = 0; index < entries[0].target.children.length; index++) {
//       const values = entries[0].target.children.item(index).classList.values();
//       let arr = [];
//       for (const iterator of values) {
//         if (iterator.indexOf("animate__") !== -1) {
//           arr.push(iterator);
//         }
//       }
//       if (arr.length > 0) {
//         observer.classList = arr;
//       }
//       arr.forEach((item) => {
//         entries[0].target.children.item(index).classList.remove(item);
//       });
//     }
//   }
// };

export function Aobservers() {
  return new IntersectionObserver(setclass, {
    threshold: 0.1,
    // rootMargin: "20px"
  });
}
export const Tobservers = new IntersectionObserver(setclass, {
  threshold: 0.1,
});
