<template>
  <div class="nav" :class="isFiexd ? 'fiexd-nav' : ''">
    <div class="box">
      <img class="logo" src="../assets/logo.png" alt="" @click="goRoute('/')" />
      <el-menu
        :default-active="activeIndex"
        class="el-menu-demo"
        mode="horizontal"
        :router="true"
      >
        <el-menu-item index="/">首页</el-menu-item>
        <el-submenu index="2">
          <template slot="title">产品与服务</template>
          <el-menu-item index="/qcygUs">企才易管</el-menu-item>
          <el-menu-item index="/Yunlan">薪云工资条</el-menu-item>
          <el-menu-item index="/faxinIndex">禾悦发薪</el-menu-item>
          <el-menu-item index="/lzy">灵云智</el-menu-item>
        </el-submenu>
        <el-menu-item index="/newList">新闻动态</el-menu-item>
        <el-menu-item index="/contac">关于我们</el-menu-item>
      </el-menu>
    </div>
  </div>
</template>

<script>
export default {
  props: ["isFiexd"],
  data() {
    return {
      activeIndex: "/",
      menuList: [
        {
          name: "首页",
          isChindren: false,
          children: [],
          path: "/",
        },
        {
          name: "产品与服务",
          isChindren: true,
          children: [
            {
              name: "企才易管",
              path: "/qcygUs",
            },
            {
              name: "薪云工资条",
              path: "/Yunlan",
            },
            {
              name: "禾悦发薪",
              path: "/",
            },
            {
              name: "灵云智",
              path: "/lzyIndex",
            },
          ],
        },
        {
          name: "新闻动态",
          isChindren: false,
          children: [],
          path: "/news",
        },
        {
          name: "关于我们",
          isChindren: false,
          children: [],
          path: "/contact",
        },
      ],
    };
  },
  methods: {
    handleSelect(key, keyPath) {
      console.log(key, keyPath);
    },
    goRoute(url) {
      this.$router.push(url);
    },
  },
};
</script>

<style scoped>
::v-deep .el-menu.el-menu--horizontal {
  border-bottom: none !important;
}
.logo {
  width: 117px;
  height: 38px;
}
.box {
  width: 1200px;
  height: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.nav {
  width: 100%;
  height: 64px;
  background: #ffffff;
  z-index: 999;
}
</style>
