import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/index",
  },
  {
    path: "/index",
    component: () => import("@/views/index.vue"),
  },
  {
    path: "/yunlan",
    component: () => import("@/views/Yunlan.vue"),
  },
  {
    path: "/newList",
    component: () => import("@/views/news.vue"),
  },
  {
    path: "/newsInfo",
    component: () => import("@/views/newsInfo.vue"),
  },
  {
    path: "/contac",
    component: () => import("@/views/contactUs.vue"),
  },
  {
    path: "/qcygUs",
    component: () => import("@/views/qcygUs.vue"),
  },
  {
    path: "/lzy",
    component: () => import("@/views/lzyIndex.vue"),
  },
  {
    path: "/faxinIndex",
    component: () => import("@/views/faxin.vue"),
  },
  {
    path: "/probateIndex",
    component: () => import("@/views/probate.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

export default router;
