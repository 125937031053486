<template>
  <div id="app">
    <Header :isFiexd="isFiexd" />
    <router-view />
    <Bottom />
    <el-backtop target=".nav"></el-backtop>
  </div>
</template>

<script>
import Header from "@/components/headerIndex";
import Bottom from "@/components/bottomIndex";

export default {
  components: { Header, Bottom },
  data() {
    return {
      isFiexd: false,
    };
  },
  watch: {
    $route: function () {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    },
  },
  mounted() {
    // 添加滚动事件监听器
    window.addEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      if (window.scrollY > 0) {
        this.isFiexd = true;
      } else {
        this.isFiexd = false;
      }
    },
  },
};
</script>

<style>
.fiexd-nav {
  position: fixed;
  top: 0;
}
* {
  padding: 0;
  margin: 0;
}
div,
p,
span {
  box-sizing: border-box;
}
#app {
  min-width: 1200px;
}
</style>
