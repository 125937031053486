import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import ElementUI from "element-ui";
// import axios from "axios"
import "animate.css";
import "element-ui/lib/theme-chalk/index.css";
import { Aobservers, Tobservers } from "@/util/observer";
Vue.config.productionTip = false;

Vue.prototype.$observers = Aobservers;
Vue.prototype.$Tobservers = Tobservers;

Vue.use(ElementUI);
new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
